import moment from "moment"
import { Moment } from "moment"
import { GLOBAL_FORMAT } from "../../data/const/format-date"
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

export interface IUtils {
    isNumber : (str : string|number) => boolean 
    getVideoEmbeded : (url : string) => string
    capitalize : (str : string) => string
    strToDate : (date : string, format ?: string ) => Moment,
    dateToStr : (date : Date, format ?:string) => string,
    dateToMoment : (date: Date) => Moment ,
    descargarPDF : (contenido : any,fileName : string) => void
}


export const Utils : IUtils = {

    getVideoEmbeded : (url : string) : string => {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
    
        const out =  (match && match[2].length === 11)
          ? match[2]
          : null;
    
    
        return "//www.youtube.com/embed/"+ out;
    },

    capitalize(str: string): string {
      return (str && str[0].toUpperCase() + str.slice(1)).replace('_',' ');
    }
    ,
    isNumber(value : string|number) : boolean {
      return !Number.isNaN(Number(value));
    },
    strToDate(date:string, format ?: string) : Moment{
      if(!format){
        format = GLOBAL_FORMAT;
      }  
      return moment(date,format);
    }
    ,
    dateToStr(date : Date , format ?:string) : string {
      if(!format){
        format = GLOBAL_FORMAT;
      }  
      return moment(date).format(format);
    },
    dateToMoment(date : Date) : Moment {
      return moment(date);
    },
    
    async descargarPDF(contenido: any, fileName: string) {
      fileName += fileName.endsWith('.pdf') ? '' : '.pdf';
    
      try {
        const nativeElement  =contenido.nativeElement;
        const canvas = await html2canvas(nativeElement, {
          scale: 2,
          useCORS: true,
          logging: false,
          windowWidth: nativeElement.scrollWidth, 
          windowHeight: nativeElement.scrollHeight
        });
     
        const printWindow = window.open('', '_blank');
        
        if (!printWindow) {
          throw new Error('Could not open print window');
        }
     
        printWindow.document.write(`
          <html>
            <head>
              <title>Print</title>
              <style>
                img { max-width: 100%; }
                @media print {
                  @page { margin: 0; }
                  body { margin: 1.6cm; }
                }
              </style>
            </head>
            <body>
              <img src="${canvas.toDataURL()}" />
            </body>
          </html>
        `);
     
        printWindow.document.close();
        printWindow.focus();
        
        printWindow.onload = () => {
          printWindow.print();
          printWindow.close();
        };
    
      } catch (error) {
        console.error('Error al generar el PDF:', error);
        throw error;
      }
    }
  }
