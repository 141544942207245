import { IMyothernestInfo } from "../interfaces/myothernest.interface";




export const INFO_MYOTHERNEST : IMyothernestInfo = {
    social_media : [
        {
            image: "/assets/images/footer/footer-youtube.png",
            link : "https://www.youtube.com/@Myothernest",
            title: "youtube"
        },
        {
            image: "/assets/images/footer/footer-instagram.png",
            link : "https://www.instagram.com/myothernest/",
            title : "instagram"
        },
        {
            image: "/assets/images/footer/footer-facebook.png",
            link : "https://www.facebook.com/myothernest/",
            title :"facebook"
        }
    ],
    phone_contact_us: "+353877019667",
    phones : [
        "+353 1 231 4628",
        "+353 87 701 9667"
    ],
    company_name: "SCULPT FUSION LTD",
    office : {
        address : "Mespil House, Sussex Rd",
        county: "Dublin",
        number : "4",
        eircode : "D04 T4A6",
        country : "Ireland"
    },
    email : ["hello@myothernest.com"],

    emails : {
        general : "hello@myothernest.com",
        important : "accounts@myothernest.com",
        support : "help@myothernest.com"
    }
}