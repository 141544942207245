import { Inject, Injectable, makeStateKey, Optional, PLATFORM_ID, TransferState } from '@angular/core';
import { Erol, Iuser } from '../interfaces/user.interface';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';


const COOKIES_SERVER = makeStateKey<any>('COOKIES_SERVER');

@Injectable({
  providedIn: 'root'
})
export class AuthService {



  private readonly _itemName : string = 'token';
  private readonly _itemUser : string = 'user';
  private readonly _itemProfileImage : string = 'profile_icon';
  readonly DELETE : string[] = [this._itemName,this._itemUser,'csrftoken', 'profile_icon'];
  readonly domain = undefined;
  private _user ?: Iuser;

  private dataSubject = new BehaviorSubject<boolean|null>(null);
  private data$: Observable<boolean|null> = this.dataSubject.asObservable();



  constructor(
    private cookieService: SsrCookieService,
    private api  :ApiService,
    private transfer : TransferState,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject('REQUEST') private request: any,
    private router : Router) {

  }

  parseCookies(cookies : string) {
    const cookiesObj  : any = {};

    // Dividir las cookies en un array de pares clave-valor
    const cookieArr = cookies.split('; ');

    // Iterar sobre cada par clave-valor
    cookieArr.forEach(cookie => {
      const [key, value] = cookie.split('=');

      // Intentar convertir valores con formato JSON a objetos
      try {
        // Si el valor es un JSON válido, parsearlo
        cookiesObj[key] = JSON.parse(decodeURIComponent(value));
      } catch (e) {
        // Si no es JSON, guardar el valor tal cual
        cookiesObj[key] = decodeURIComponent(value);
      }
    });

    return cookiesObj;
  }

  isLandlord(){
    const user : Iuser | undefined = this.getUser();

    if(!user) return false;
    return user.rol == Erol.LANDLORD;
    
  }
  hasPermission(landlord?: string) {
    const user : Iuser | undefined = this.getUser();

    if(!user) return false;
    return user.rol == Erol.ADMIN || user.rol == Erol.SA || (user.rol == Erol.LANDLORD && landlord && user.landlord == landlord)
  }
  isLogged() : boolean{
    if(this.cookieService.check(this._itemName)) return true;
    if(isPlatformServer(this.platformId) && (this.request ?? false)){
      if(!this.request.headers.cookie) return false;
      const cookies = this.parseCookies(this.request.headers.cookie);
      if(!(cookies[this._itemName] ?? false)) return false;
      
    
      this.transfer.set(COOKIES_SERVER,cookies);
      this.fillAll(cookies);
      return true;
    }

    if(isPlatformBrowser(this.platformId)){
      const cookies = this.transfer.get(COOKIES_SERVER,null);
      if(cookies == null) return false;
      this.fillAll(cookies);
      return true;
    }

    return false;
  } 



  fillAll(cookies?:any){
    if(!cookies){

      cookies = this.transfer.get(COOKIES_SERVER,null);
      if(cookies == null) return;
    }
    this._user = cookies[this._itemUser];
    this.cookieService.set(this._itemUser,JSON.stringify(cookies[this._itemUser]),undefined,'/',this.domain,true,'Lax');
    this.cookieService.set(this._itemName,JSON.stringify(cookies[this._itemName]),undefined,'/',this.domain,true,'Lax');
    this.cookieService.set(this._itemProfileImage,JSON.stringify(cookies[this._itemProfileImage]),undefined,'/',this.domain,true,'Lax');
    
  }

  login(user : Iuser, token : string, update: boolean = true){
    
    this._user = user;
    this.cookieService.set(this._itemUser,JSON.stringify(user),undefined,'/',this.domain,true,'Lax');
    this.cookieService.set(this._itemName, token,undefined,'/',this.domain,true,'Lax');
    this.transfer.set(COOKIES_SERVER,this.cookieService.getAll());

    if(update) this.forceUpadte();
  }

  forceUpadte(){
    this.dataSubject.next(true);
  }

  logout(){
    delete this._user;

    this.DELETE.forEach((d)=>{
      this.cookieService.delete(d,'/',this.domain,true,'Lax');
    });
    this.transfer.remove(COOKIES_SERVER);
    this.dataSubject.next(false);

  }


  getDashboard(){

    if(!this.isLogged()) return {links : [], buttons : []};

    const BUTTONS = [


      {
        link : "profile",
        name : "Profile",
        icon : "person"
      },
      {
        value : "logout",
        name: "Logout",
        icon: "logout",
        click : (event ?: any) => {
          if(event){
            event.stopPropagation();
            event.preventDefault();
          }
          firstValueFrom(this.api.post({method:'auth/logout',auth:this.getToken()}));
          this.logout();
          window.location.reload();
        }
      },
    ]

    const rol = this.getUser()?.rol;
    const LINKS : any=  [];
    let addDash : any = [
      // {
      //   link : "",
      //   name : "Dashboard",
      //   icon : "apps"
      // },
    ];

    if(rol != undefined && rol != null && [Erol.ADMIN,Erol.SA,Erol.MODERATOR, Erol.LANDLORD].includes(rol)){
      
      LINKS.push(...
        [
          ...addDash,
          {
            link : 'listings',
            name : "Listings",
            icon : 'list',
          },
          {
            link : "booking-requests",
            name : "Booking requests",
            icon : "pending"
          },
          {
            link : "bookings",
            name : "Bookings",
            icon : "collections_bookmark"
          },
          {
            link : "visitor-guide",
            name : "Visitor Guide",
            icon : "picture_as_pdf"
          }
        ]
      );
      addDash = [];
    }



    const USERLINKS = [
      ...addDash,
      {
        link : "my-requests",
        name : "My requests",
        icon : "style"
      },
      {
        link : "invoices",
        name : "Invoices",
        icon : "receipt"
      }
    ]
    return {links : LINKS , userLinks: USERLINKS, buttons: BUTTONS};
  }

  removeProfileIcon() {
    this.cookieService.delete(this._itemProfileImage)
  }

  hasProFileIcon() : boolean{
    this.isLogged();

    if(isPlatformServer(this.platformId)){
      const cookies = this.transfer.get(COOKIES_SERVER,null);
      if(cookies != null){
        if(cookies[this._itemProfileImage]) return true;
        return false;
      }
    }

    return this.cookieService.get(this._itemProfileImage) == 'true';
  }

  setProfileIcon(has : boolean) {
    this.cookieService.set(this._itemProfileImage,has.toString() ,undefined,'/',this.domain,true,'Lax');
  }

  getUser() : Iuser|undefined{
    if(this._user) return structuredClone(this._user);

    if(isPlatformServer(this.platformId)){
      this.fillAll();
    }
    const user : string = this.cookieService.get(this._itemUser);
    if(!user) return undefined; 

    this._user = JSON.parse(user);

    return this._user;
  }

  getHttpHeaders(){

    if(!this.isLogged()) return new HttpHeaders();

    return new HttpHeaders(
      {
        'Authorization' : `Token ${this.getToken()}`
      }
    )
  }

  getToken(){
    if(isPlatformServer(this.platformId)){
      const cookies = this.transfer.get(COOKIES_SERVER,null);
      if(cookies != null){
        if(cookies[this._itemName]) return cookies[this._itemName];
      }
    }
    return this.cookieService.get(this._itemName);
  }


  public o_login () : Observable<boolean|null> {
    return this.data$;
  }

}
